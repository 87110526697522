import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  StyledModal,
  StyledTitle,
  StyledBody,
  DetailInitiativeWrapper,
} from "./styles";
import { connect } from "react-redux";
import { ImageUrl } from "../../../utils/constants";
import { /* DownloadIconNew, */ InitiativeDownloadIcon, /* ViewProfileIcon, */ } from "../../../utils/icons";
import LazyImage from "../../common/LazyImage/LazyImage";
import HoverButton from "../../common/HoverButton";
import { ProgressBar } from "../../HealthTabV2/HealthTabUpdated/styles";
import { fetchApi } from "../../../utils/methods";
import { toast } from "react-toastify";
import { GetInitiativeById, GetInitiativeReport } from "../../../redux/constants/apiConstants";
import { Border } from "../../EventDetails/styles";
import CSVList from "../../AttendeesModal/CSVList";
import moment from "moment";


class CompanyInitiative extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initiative: null,
      printCSV:false,
      isloading:false,
      initiativeReportData:[],
      headers:[
        { label: "First Name", key: "fname" },
        { label: "Last Name", key: "lname" },
        { label: "Email", key: "mail" },
        // { label: "Start Date", key: "start_date" },
        { label: "Completed On", key: "completed_on" },
      ]
    };
  }
  componentDidMount() {
    this.props.companyId && this.getInitiativeDetail();
  }

  getInitiativeDetail = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const { companyId, detailInitiative } = this.props;
      const startDate = moment(detailInitiative?.start_date).format('YYYY-MM-DD');
      const endDate = moment(detailInitiative?.end_date).format('YYYY-MM-DD');
      const postData = {
        company_id: companyId,
        initiative_id: detailInitiative.id,
        start_date: startDate,
        end_date: endDate,
        year: currentYear
      }; 
      const res = await fetchApi(
        GetInitiativeById,
        "POST",
        postData,
        {},
        this.successFunc
      );
      if (res.message) {
        toast.error(res.message);
      }
      this.setState({ initiative: res.data[0] });
    } catch (error) {
      toast.error(error.message);
    }
  };

  getInitiativeReport = async () => {
    try {
      const { companyId, detailInitiative } = this.props;
      const startDate = moment(detailInitiative?.start_date).format('YYYY-MM-DD');
      const endDate = moment(detailInitiative?.end_date).format('YYYY-MM-DD');
      const res = await fetchApi(
        `${GetInitiativeReport}/${companyId}/${detailInitiative.id}/${startDate}/${endDate}`,
        "GET",
        null,
        {},
        this.successFunc
      );
      if (res.message) {
        toast.error(res.message);
      }
      this.setState({ initiativeReportData: res.data }, () => {
        this.setState({ printCSV: true });
      });
    } catch (error) {
      toast.error(error.message);
    }
  };
  showDetailInitiative = () => {
    const { initiative } = this.state;
    return (
      <React.Fragment>
        <div className="row-one">
          <div style={{ display: "flex" }}>
            <div>
              <LazyImage
                style={{ width: "60px", height: "60px" }}
                src={ImageUrl + "/" + initiative.initiative_image}
              />
            </div>
            <div className="details">
              <div>
                <span className="name">{initiative.initiative_name}</span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span className="points">
                  {initiative.is_upcoming ? "Upcoming" : "Active"} | +
                  {initiative.points} pts
                </span>
              </div>
            </div>
          </div>

          {!initiative.is_upcoming ? <div style={{ marginRight: "0px" }} onClick={()=>this.getInitiativeReport()}>
            <HoverButton
              width={"40px"}
              height={"40px"}
              title={"Download"}
              fillOpacity={1}
              disabled={false}
              backgroundColor={"#f0f6f8"}
              backgroundColorActive={"#005c87"}
              activeColor={"#007AB1"} //{"#619ab5"}
              inActiveColor={"white"}
              // svgPath={<DownloadIconNew />}
              svgPath={<InitiativeDownloadIcon />}
            />
          </div>: null}
        </div>
        <div className="row-two">
          <div style={{display:"flex", alignItems:"center"}}>
            <span className="user-count" style={{color:initiative.font_color, fontSize: '24px', lineHeight: '24px', fontFamily: 'Rubik-Medium'}}>{initiative.initiative_count} Users</span> <span className="text" style={{ fontSize: '16px', lineHeight: "20px", color: '#005C8799', marginLeft:"12px" }}> completed out of {initiative.user_count}</span> <span className="user-count" style={{ color: 'rgba(0, 92, 135, 0.6)', fontFamily: 'rubik' }} /* style={{color:initiative.font_color}} */> </span> 
          </div>
          <div>
            <span className="percent" style={{color:initiative.font_color, fontSize: '24px', lineHeight: '24px', fontFamily: 'Rubik-Medium'}}>{initiative.percentage}%</span>
          </div>
        </div>

        <div
          className="row-three"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ProgressBar
              background={"#005C870D"}
              backgroundActive={initiative.color}
              width={initiative.percentage > 100 ? 100 : initiative.percentage}
              height={"20px"}
              // style={{height:"20px"}}
            >
              <div className="progress" style={{paddingRight:"20px",boxShadow:"unset"}}></div>
            </ProgressBar>
          </div>
          {/* <div style={{marginLeft:"10px"}}><span className="taken">Taken</span></div> */}
        </div>
        <Border style={{background:'#005C871A',margin:'0px',width:"100%",marginTop:"20px"}}/>

        <div className="row-four" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="other-detail" style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: '12px' }}><span className="title">Start Date</span></div>
            {/* <div><span className="info">{initiative.launch_date ? moment(new Date(initiative.launch_date)).format("MMM DD, YYYY") : moment(new Date(initiative.initiative_created_at)).format("MMM DD, YYYY") }</span></div> */}
            <div><span className="info">{ moment(new Date(initiative?.start_date)).format("MMM DD, YYYY") }</span></div>
          </div>

          <div className="other-detail" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <div style={{ marginBottom: '12px' }}><span className="title">End Date</span></div>
            {/* <div><span className="info">{moment(new Date(initiative.end_date)).format("MMM DD, YYYY")}</span></div> */}
            <div><span className="info">
              {/* {initiative.end_date ? 
                moment(new Date(initiative.end_date)).format("MMM DD, YYYY") : 
                `Dec 31, ${initiative.launch_date ? moment(new Date(initiative.launch_date)).year() : moment(new Date(initiative.initiative_created_at)).year()}`
              } */}
              {
                moment(new Date(initiative?.end_date)).format("MMM DD, YYYY")
              }
            </span></div>
          </div>

          {/* <div className="other-detail">
            <div><span className="title">Run</span></div>
            <div><span className="info">{initiative.launch_date || "-"}</span></div>
          </div> */}

        </div>
        
      </React.Fragment>
    );
  };

  downloadEnrolledList = (bool) => {
    this.setState({
      printCSV: bool,
      isloading: bool
    })
  };
  render() {
    const { showModal, onClose /*detailInitiative*/ } = this.props;
    return (
      <StyledModal
        transform={1}
        show={showModal}
        newWidth={"600px"}
        newBg={"newBg"}
        height="50px"
        display="none"
      >
        <Modal.Header
          closeButton
          onHide={() => onClose()}
          style={{ background: "white" }}
        >
          <StyledTitle style={{ color: "#005C87", width:"100%", fontSize: "18px", justifyContent:"flex-start", marginLeft: '35%' }}>
            Initiative Info
            <div  onClick={() => onClose()} style={{display:"flex", margin:"auto 0 auto auto", cursor:"pointer"}}>
              <img src={ImageUrl+"/images/AdminReports/close.png"} width="24px" height="24px"/>
            </div>
          </StyledTitle>
        </Modal.Header>
        <StyledBody style={{padding: "24px 24px 16px 24px"}}>
          {/* <StyledTitle color={'color'}>{companyInitiatives && companyInitiatives.length >0?"Select a Company Initiative":"No Comapny Initiatives"}</StyledTitle> */}
          <DetailInitiativeWrapper>
            {this.state.initiative && this.showDetailInitiative()}
          </DetailInitiativeWrapper>
        </StyledBody>

        {this.state.printCSV &&
              <CSVList
                Title={`${this.state.initiative.initiative_name}_report`}
                removeCSVData={()=>this.setState({printCSV:false})}
                headers={this.state.headers}
                data={this.state.initiativeReportData} />
        }
      </StyledModal>
    );
  }
}
CompanyInitiative.propTypes = {
  showModal: PropTypes.bool.isRequired,
  companyPopup: PropTypes.func,
  onClose: PropTypes.func,
  detailInitiative: PropTypes.object,
  companyId: PropTypes.number,
  history: PropTypes.object,
  userId:PropTypes.number,
  setPage: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userId: state.profileData.userId,
})

export default connect(mapStateToProps, null)(CompanyInitiative);
